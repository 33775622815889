import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import { RadioGroup, Radio } from 'react-radio-group'
import { connect } from 'react-redux';
import { withAuthorization } from '../Session';

const condition = authUser => !!authUser;

class PenantiesRadioGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: this.props.player.penalty,
            player: this.props.player
        }
    }

    handleChange = async (value) => {
        await this.setState({
            selectedValue: value,
            player: { ...this.state.player, penalty: value }
        })
        await this.props.playerOnChange(this.state.player);
    }

    render() {
        return (
            <RadioGroup Component="tr"
                selectedValue={this.state.selectedValue}
                onChange={(value) => this.handleChange(value)}>
                <td>{this.props.index}</td>
                <td className="pen-name">{this.props.player.name}</td>
                <td className="td-pen-check pen-none"><Radio disabled={this.props.currentUser && parseInt(this.props.currentUser.role) !== 1} value="none" /></td>
                <td className="td-pen-check pen-10k"><Radio disabled={this.props.currentUser && parseInt(this.props.currentUser.role) !== 1} value="Late5" /></td>
                <td className="td-pen-check pen-20k"><Radio disabled={this.props.currentUser && parseInt(this.props.currentUser.role) !== 1} value="Late10" /></td>
                <td className="td-pen-check pen-30k"><Radio disabled={this.props.currentUser && parseInt(this.props.currentUser.role) !== 1} value="Absense" /></td>
                <td className="td-pen-check pen-custom"><Radio disabled={this.props.currentUser && parseInt(this.props.currentUser.role) !== 1} value="Custom" /></td>
                <td className="td-pen-check pen-50k"><Radio disabled={this.props.currentUser && parseInt(this.props.currentUser.role) !== 1} value="LackPlayer" /></td>
                <td className="td-pen-check pen-noreg"><Radio disabled={this.props.currentUser && parseInt(this.props.currentUser.role) !== 1} value="NoReg" /></td>
            </RadioGroup>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        stCurrentUser: state.currentUser
    }
}

const reduxConnectExport = connect(mapStateToProps)(PenantiesRadioGroup)
export default compose(withFirebase, withAuthorization(condition))(reduxConnectExport)