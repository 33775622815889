import React, { Component } from 'react';
import PageWrapper from './components/content/PageWrapper'
import { BrowserRouter } from 'react-router-dom';
import Login from './components/content/Login';
import { Route } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import { withAuthentication } from './components/Session';
import { AuthUserContext } from './components/Session';
import { ToastContainer} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <BrowserRouter>
      <ToastContainer/>
        <AuthUserContext.Consumer>
          {
            authUser =>
              authUser ? <>
              </>
                : <Route exact path={ROUTES.LOGIN} component={Login} />
          }
        </AuthUserContext.Consumer>
        <Route exact path={ROUTES.ROOT} component={PageWrapper} />
        <Route path={ROUTES.MAIN} component={PageWrapper} />
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
      </BrowserRouter>
    )
  }
}

export default withAuthentication(App)
