import * as firebase from 'firebase';
import 'firebase/auth';

// Your web app's Firebase configuration
var config = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    databaseURL: process.env.REACT_APP_databaseURL,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId
};

class Firebase {
    constructor() {
        !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
        this.auth = firebase.app().auth();
        this.db = firebase.app().database();
        this.storage = firebase.app().storage();
    }

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

    getRef = (path) => this.db.ref(path);

    getStorage = () => this.storage;

}
export default Firebase;