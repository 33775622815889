import React, { Component } from 'react';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { connect } from 'react-redux';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import uid from 'uid';
import Modal from 'react-bootstrap/Modal'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { withAuthorization } from '../Session';
import { toast } from 'react-toastify'

const moment = require('moment');

const condition = authUser => !!authUser;

class MatchRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bigRegisteredChecked: false,
            selectedDate: moment(Date.now()).add(1, 'days').toDate(),
            isShowConfirmModal: false,
            // isLocked: false,
            dateId: moment(Date.now()).add(1, 'days').format('yyyyMMDD'),
            yesPlayers: 0,
            noPlayers: 0,
            players: [
                {
                    playerId: "",
                    name: "",
                    email: "",
                    level: "",
                    status: "",
                    role: "",
                    penalty: "",
                    isRegistered: "",
                    team: "",
                }
            ],
            isRegistered: true,
            freelancers: [{ playerId: 'flr_' + this.dateId + '_' + uid(), name: "" }],
            deleteFlr: [],
            avatarList: [{playerId: "", playerAvatar: ""}]
        }
    }

    async componentWillMount() {
        this.props.dpSetMatchDate(moment(new Date()).add(1, 'days').format('ddd, DD MMM yyyy'))
        await this.getMatch(this.state.dateId);
    }


    CalendarButton = () => {
        const ExampleCustomInput = ({ value, onClick }) => (
            <button className="btn btn-primary btn-footer btn-calendar" onClick={onClick}>
                <i className="fa fa-calendar"></i> {value}
            </button>
        );
        return (
            <DatePicker
                dateFormat="EEE, d MMM"
                todayButton="Today"
                selected={this.state.selectedDate}
                onChange={(value) => this.calendarSelect(value)}
                customInput={<ExampleCustomInput />}
            />
        );
    };

    LockButton = () => {
        var disable = true;
        if (this.props.currentUser != null) {
            disable = (parseInt(this.props.currentUser.role) !== 1) || !(this.state.players.length > 0)
        }
        var styleIcon = "fa fa-lock";
        (this.state.isLocked === true) ? styleIcon = "fa fa-lock" : styleIcon = "fa fa-unlock"
        this.props.dpLockSetTeam(this.state.isLocked)
        return <button type="button" disabled={disable} className="btn btn-primary btn-footer btn-calendar"
            onClick={() => this.switchLock()}>
            <i className={styleIcon} aria-hidden="true"></i></button>
    }

    DeleteButton = () => {
        var disabled = true;
        if (this.props.currentUser != null) {
            disabled = (parseInt(this.props.currentUser.role) !== 1) || !(this.state.players.length > 0)
        }
        return <button type="button" disabled={disabled} onClick={() => this.setState({ isShowConfirmModal: true })} className="btn btn-primary mr-1"><i className="fa fa-trash"></i> </button>
    }

    getDayOfWeekNo = (ddd) => {
        switch (ddd) {
            case "Mon":
                return 1;
            case "Tue":
                return 2;
            case "Wed":
                return 3;
            case "Thu":
                return 4;
            case "Fri":
                return 5;
            case "Sat":
                return 6;
            case "Sun":
                return 7;
            default:
                break;
        }
    }

    RefreshButton = () => {
        var disabled = true;
        var refreshPlayer = () => {
            var currentPlayers = [...this.state.players];

            var playerRef = this.props.firebase.getRef('players');
            playerRef.once('value').then(async (playerSnap) => {
                var newPlayers = {};
                playerSnap.forEach(player => {
                    if (parseInt(player.val().status) !== -1) {
                        var isRegistered = false;
                        var dowId = moment(this.state.selectedDate).format('ddd');
                        dowId = this.getDayOfWeekNo(dowId)
                        if(player.val().dow) {
                            if (player.val().dow[dowId] && player.val().dow[dowId] === true) {
                                isRegistered = true;
                            }
                        }
                        var penalty = '';
                        var team = '';
                        var temp = currentPlayers.find(cp => cp.playerId === player.key)
                        if (temp) {
                            isRegistered = temp.isRegistered;
                            penalty = temp.penalty;
                            team = temp.team;
                        }
                        newPlayers = {
                            ...newPlayers, [player.key]: {
                                isRegistered: isRegistered,
                                name: player.val().name, isMatchPay: player.val().isMatchPay | 0, 
                                penalty: penalty,
                                team: team
                            }
                        }
                    }
                });

                var matchRef = this.props.firebase.getRef('matches/' + this.state.dateId + '/players');
                matchRef.update(newPlayers, async (error) => {
                    if (error) {
                        console.log(error.errorMessage);
                    }
                    await this.getMatch(this.state.dateId);
                    await this.setTeam();
                    toast.success('Match created successfully!', {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                })
            });
        }
        if (this.props.currentUser != null) {
            disabled = (parseInt(this.props.currentUser.role) !== 1)
        }
        return !this.state.players.length && <button type="button" disabled={disabled} onClick={() => refreshPlayer()} className="btn btn-primary mr-1">{<i className="fa fa-hammer"></i>} </button>
    }

    switchLock = async () => {
        await this.getMatch(this.state.dateId)
        await this.setTeam()
        await this.setState({ isLocked: !this.state.isLocked })
        await this.props.firebase.getRef('matches/' + this.state.dateId).update({ isLocked: this.state.isLocked }, (error) => {
            if (error) { console.log(error.message); }

        })
        if(this.state.isLocked)
        {
            toast.info('⚽Locked!', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
        else
        {
            toast.info('⚽Unlocked!', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
        await this.saveFreelancers();
        this.props.dpDisableSendMail(this.state.isLocked);
    }

    calendarSelect = async (value) => {
        var dateId = moment(value).format('yyyyMMDD');

        await this.setState({ selectedDate: value, dateId: dateId });
        this.props.dpSetMatchDate(moment(value).format('ddd, DD MMM yyyy'));
        this.props.dpInitialSetTeam(true);

        await this.getMatch(dateId);
    }

    getMatch = async (dateId) => {
        var matchRef = this.props.firebase.getRef('matches/' + dateId);
        matchRef.once('value', matchSnap => {
            var playerMatch = [];
            var freelancersMatch = [];
            var yesPlayers = 0, noPlayers = 0;
            matchSnap.child("players").forEach(m => {
                if (m.key.substring(0, 4) !== 'flr_') {
                    playerMatch.push({ playerId: m.key, ...m.val() });
                } else {
                    freelancersMatch.push({ playerId: m.key, ...m.val() });
                }
                (m.val().isRegistered === true) ? yesPlayers++ : noPlayers++;
            });

            if (freelancersMatch.length < 1) {
                freelancersMatch = [{ playerId: 'flr_' + this.dateId + '_' + uid(), name: "" }]
            }

            var isLocked = (matchSnap).child("isLocked").val();
            if (!isLocked) { isLocked = false }
            var isSent = (matchSnap).child("isSent").val()
            var playerRef = this.props.firebase.getRef('players');
            playerRef.once('value').then((playerSnap) => {
                var players = [];
                var emailList = []
                playerSnap.forEach(player => {
                    players.push({ playerId: player.key, ...player.val() });
                    if(parseInt(player.val().status) !== -1) {
                        emailList.push(player.val().email);
                    }                    
                });
                this.props.dpSetEmailList(emailList);
                var res = playerMatch.map(pMatch => {
                    const p = { ...players.find(p => pMatch.playerId === p.playerId) }
                    delete p.playerId;
                    return { ...pMatch, ...p }
                })

                var avatarL = [{playerId: "", playerAvatar: ""}];
                res.map(async(p) => {
                    var avatarLink = await this.getPlayerAvatar(p.playerId);
                    avatarL.push({playerId: p.playerId, playerAvatar: avatarLink});
                    this.setState({avatarList: avatarL});
                });

                var freelancers = []
                if (this.props.stFreelancers === null) {
                    freelancers = freelancersMatch;
                } else {
                    freelancers = this.props.stFreelancers
                }

                var arrPlayers = [...res.filter(player => player.isRegistered === true), ...freelancers.filter(flr => flr.name !== '')];

                var teamTemp = {
                    teamA: {
                        level: 0,
                        players: arrPlayers.filter(player => player.team === "A")
                    },
                    teamB: {
                        level: 0,
                        players: arrPlayers.filter(player => player.team === "B")
                    }
                }

                var sendPlayer = {
                    plyA : arrPlayers.filter(player => player.team === "A").map(p => p.name),
                    plyB : arrPlayers.filter(player => player.team === "B").map(p => p.name),
                }

                this.props.dpSetSendPlayer(sendPlayer);

                if (this.props.currentUser !== null && res.length > 1) {
                    var currentP = res.find(p => p.playerId === this.props.currentUser.uid);
                    this.setState({ bigRegisteredChecked: currentP && currentP.isRegistered })
                }
                this.props.dpSetTeamTemp(teamTemp)
                this.setState({ isLocked: isLocked, isSent: isSent, players: res, freelancers: freelancers, yesPlayers: yesPlayers, noPlayers: noPlayers });
                this.setTeam();
            });

        });

    }

    deleteMatch = async (dateId) => {
        await this.props.firebase.getRef('matches/' + dateId).remove();
        await this.setState({ players: [], freelancers: [], isShowConfirmModal: false })
        await this.props.dpSetRegisteredPlayers([]);
        await this.props.dpSetTeamTemp(null);
        toast.success('Match deleted successfully!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    }
    toggleRegister = (event, playerId) => {
        this.props.firebase.getRef('matches/' + this.state.dateId + '/players/' + playerId).update({ isRegistered: event.target.checked }, (error) => {
            if (error) { console.log(error.message); } else {
                this.getMatch(this.state.dateId);
            }
        })
    }

    BigRegister = () => {
      const onChange = (checked) => {
        if (this.props.currentUser !== null) {
          this.props.firebase
            .getRef("matches/" + this.state.dateId + "/players/" + this.props.currentUser.uid)
            .update({ isRegistered: checked }, (error) => {
              if (error) {
                console.log(error.message);
              } else {
                this.setState({ bigRegisteredChecked: checked });
                this.getMatch(this.state.dateId);
                if (checked) {
                  toast.info("😁 Yay! Good boy!", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                } else {
                  toast.info("😥 Oops! So sad!", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              }
            });
        }
      };

      if (this.state.isLocked !== true) {
        if (this.state.stCurrentUser !== null && this.state.players.length > 1) {
          var p = this.state.players.find((p) => this.props.currentUser.uid === p.playerId);
          if (Number(p.status) === 1) {
            return (
              <>
                <img src="https://media.giphy.com/media/fxBW4CGDnKwCxXK3nZ/giphy.gif" height="35" width="35" alt="Click here" />
                <BootstrapSwitchButton
                  checked={this.state.bigRegisteredChecked}
                  key={this.state.bigRegisteredChecked}
                  onlabel={
                    <div>
                      <i className="fa fa-thumbs-up" /> I WILL JOIN
                    </div>
                  }
                  offlabel={
                    <div>
                      <i className="fa fa-thumbs-down" /> I SLEEP
                    </div>
                  }
                  onstyle="success"
                  offstyle="secondary"
                  width={150}
                  onChange={(checked) => onChange(checked)}
                />
              </>
            );
          }
        }
      } else {
        if (this.state.stCurrentUser !== null && this.state.players.length > 1) {
          var p2 = this.state.players.find((p2) => this.props.currentUser.uid === p2.playerId);
          if (Number(p2.status) === 1) {
            if (p2 && p2.isRegistered)
              return (
                <div className="btn-big-register bg-secondary">
                  <i className="fa fa-lock" /> I WILL JOIN
                </div>
              );
            else
              return (
                <div className="btn-big-register">
                  <i className="fa fa-lock" /> I SLEEP
                </div>
              );
          }
        }
      }
    }
    setTeam = async () => {
        var arrPlayers = await [...this.state.players.filter(player => player.isRegistered === true), ...this.state.freelancers.filter(flr => flr.name !== '')];
        await this.props.dpSetRegisteredPlayers(arrPlayers);
    }

    getRegisterSwitch = (player) => {
        if (this.props.currentUser !== null) {
            if (parseInt(this.props.currentUser.role) === 1) {
                return <div className="custom-control custom-switch" style={{ display: (this.state.isLocked) ? "none" : "block" }}>
                    <input type="checkbox" disabled={this.state.isLocked} className="custom-control-input" id={player.playerId} key={player.isRegistered} defaultChecked={player.isRegistered} onChange={(event) => this.toggleRegister(event, player.playerId)} />
                    <label className="custom-control-label" htmlFor={player.playerId}></label>
                </div>
            }
        }
    }

    getPlayerAvatar = async (uid) => {
        try {
            return await this.props.firebase
                .getStorage()
                .ref("avatar")
                .child(uid)
                .getDownloadURL();
        } catch {
            return "/img/dito_tran.png";
        }
      };

    PlayerToday = () => {
        var rowPlayer;
        this.setTeam();
        if (this.state.players.length > 0) {
            this.state.players.sort((a, b) =>
              a.name < b.name ? -1 : 1
            );
            this.state.players.sort((a, b) =>
              a.isRegistered > b.isRegistered ? -1 : 1
            );
            rowPlayer = this.state.players.map((player) => {
                var dowId = moment(this.state.selectedDate).format('ddd');
                var isDefaultJoin = false;
                dowId = this.getDayOfWeekNo(dowId)
                if (player.dow && player.dow[dowId] && player.dow[dowId] === true) {
                    isDefaultJoin = true;
                }

                var styleClass = ""; //card bg-success text-white shadow, card bg-warning text-white shadow
                var styleIcon = "";//fas fa-fist-raised, fas fa-bed
                if (player.isRegistered === true) {
                    styleClass = "card bg-success text-white shadow";
                    styleIcon = "fas fa-futbol";
                } else {
                    styleClass = "card bg-secondary text-white shadow";
                    styleIcon = "fas fa-bed";
                }

                return <div className="row" key={player.playerId} style={{justifyContent: "center"}}>
                    <div className="col-sm-8">
                        <div className={styleClass}>
                            <div className="card-body">
                                <img
                                src={this.state.avatarList.filter(p => p.playerId === player.playerId).map(p => {return p.playerAvatar})}
                                alt="Avatar"
                                className="reg-avatar"
                                />
                                <i className={styleIcon}></i> {player.name}
                            </div>
                            {(isDefaultJoin === true) ? <div className="mr-1"><i className="fa fa-flag-o"></i></div> : <></>}
                            {this.getRegisterSwitch(player)}

                        </div>
                    </div>
                </div>
            })
        } else {
            rowPlayer = <div className="row text-center">
                <div className="col-sm-12">
                    <div className='row-100px'></div>
                    <h1 className="h4">Match hasn't been created!</h1>
                    <h1 className="h4">Please contact admin</h1>
                </div></div>
        }

        return rowPlayer;
    }


    addFreelancer = () => {
        this.setState({ freelancers: [...this.state.freelancers, { playerId: 'flr_' + this.state.dateId + '_' + uid(), name: "" }] })
    }

    removeFreelancer = async (index) => {
        const list = [...this.state.freelancers];
        var deleteFlr = { ...this.state.freelancers[index] }
        list.splice(index, 1);
        await this.setState({ freelancers: list, deleteFlr: [...this.state.deleteFlr, deleteFlr] });
    }

    saveFreelancers = () => {
        var flr = {}
        var deleteFlr = {}
        var deleteItem = [];
        this.state.freelancers.forEach(freelancer => {
            if (freelancer.name !== '') {
                var temp = { ...freelancer };
                delete temp.playerId;
                flr = {
                    ...flr,
                    [freelancer.playerId]: {
                        ...temp,
                        "name": freelancer.name,
                        "isRegistered": true
                    }
                }
            } else {
                flr = { ...flr, [freelancer.playerId]: null }
            }
        })


        if (this.state.deleteFlr.length > 0) {
            this.state.deleteFlr.forEach(freelancer => {
                var temp = { ...freelancer };
                delete temp.playerId;
                deleteFlr = {
                    ...deleteFlr, [freelancer.playerId]: null
                }
            })
        }
        var tempFrl = [...this.state.freelancers];
        for (let i = deleteItem.length - 1; i >= 0; i--) {
            tempFrl.splice(deleteItem[i], 1);
        }

        flr = { ...flr, ...deleteFlr }
        this.props.firebase.getRef('matches/' + this.state.dateId + '/players/').update(flr, (error) => {
            if (error) { console.log(error.message); }
        })
        this.setState({ freelancers: tempFrl });
        this.props.dpSetFreelancers(null);
    }

    disableAddFreelancer = (index) => {
        if (this.props.currentUser !== null) return this.state.freelancers[index].name === '' || this.state.freelancers.length > 5 || this.state.isLocked || parseInt(this.props.currentUser.role) !== 1
        else return true
    }
    disableRemoveFreelancer = () => {
        if (this.props.currentUser !== null) { return this.state.isLocked || parseInt(this.props.currentUser.role) !== 1 } else return true
    }

    Freelancer = () => {
        return this.state.freelancers.map((freelancer, index) => {
            return <div className="row" key={freelancer.playerId}>
                <div className="col-7">
                    <input type="text" className="freelance-input" disabled={this.disableRemoveFreelancer()} defaultValue={freelancer.name} name={freelancer.playerId} onChange={(event) => this.onChange(event, index)} />
                </div>
                <div className="col-5 text-left">
                    {this.state.freelancers.length !== 1 && <button type="button" disabled={this.disableRemoveFreelancer()} onClick={() => this.removeFreelancer(index)} className="btn-freelance bg-dark"><i className="fas fa-minus"></i></button>}
                    {this.state.freelancers.length - 1 === index && <button type="button" disabled={this.disableAddFreelancer(index)} onClick={() => this.addFreelancer()} className="btn-freelance bg-purple"><i className="fas fa-plus"></i></button>}
                </div>
            </div>
        })
    }

    onChange = (e, index) => {
        const { value } = e.target;
        const list = [...this.state.freelancers];
        list[index].name = value;
        if (value !== '') {
            list[index].isRegistered = true;
        }
        // this.setState({ freelancers: list });
        this.props.dpSetFreelancers(list)
    }

    ConfirmationModal = () => {
        return (
            <Modal show={this.state.isShowConfirmModal} size="sm"
                backdrop="static" >
                <form>
                    <Modal.Header>
                        <Modal.Title>Delete Match</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure !?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="btn btn-primary" onClick={() => this.deleteMatch(this.state.dateId)}><i className="fa fa-trash"></i> Delete </div>
                        <div className="btn btn-secondary" onClick={() => this.setState({ isShowConfirmModal: false })}><i className="fas fa-ban"></i> Close</div>
                    </Modal.Footer>
                </form>
            </Modal>

        );
    }

    EmailSentMessage = () => {
        if (this.state.isSent === true) {
            return <div className="text-center"><div className="row text-center"><h1 className="h4 text-danger">Email sent, please reply the email </h1></div>
                <div className="row text-center"><h1 className="h4 text-danger">if you want to join</h1></div></div>
        }
    }


    render() {
        return (
            <div className="col-lg-4 col-md-6">
                {this.EmailSentMessage()}
                {this.ConfirmationModal()}
                <div className="row">
                    <div className="col-sm-12 text-center">
                        {this.CalendarButton()}
                        {
                            (this.props.currentUser && parseInt(this.props.currentUser.role) === 1) ? <>
                            {this.DeleteButton()}
                            {this.RefreshButton()}</> : <></>
                        }
                        {this.LockButton()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center mb-1">
                        {this.BigRegister()}
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-sm-12 text-center">
                        <div className="card summary bg-secondary text-white shadow">
                            <div className="card-body">
                                <i className="fas fa-futbol"></i> YES <strong>{this.state.yesPlayers}</strong> | <i className="fas fa-bed"> </i> NO  <strong>{this.state.noPlayers}</strong></div>
                        </div>
                    </div>
                </div>

                <div id="players">
                    {this.PlayerToday()}
                    {
                        (this.state.players.length > 0) ? (
                            <div id="freelancer" className="mt-2 mb-2">
                                <div className="row mb-1">
                                    <div className="ml-3 mr-2">
                                        <h5 className="text-gray-800">Freelancers</h5>
                                    </div>
                                    <div>
                                        <button type="button" disabled={this.disableRemoveFreelancer()} onClick={() => this.saveFreelancers()} className="btn-save-freelance btn-primary">Save</button>
                                    </div>
                                </div>
                                {this.Freelancer()}
                            </div>
                        ) : null
                    }
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        stCurrentUser: state.currentUser,
        stFreelancers: state.freelancers,
        stTeamTemp: state.teamTemp
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dpSetTeamTemp: (teamTemp) => {
            dispatch({ type: "SET_TEAM_TEMP", teamTemp: teamTemp })
        },
        dpSetRegisteredPlayers: (registeredPlayers) => {
            dispatch({ type: "SET_TEAM", registeredPlayers: registeredPlayers })
        },
        dpSetFreelancers: (freelancers) => {
            dispatch({ type: "SET_FREELANCERS", freelancers: freelancers })
        },
        dpLockSetTeam: (isLocked) => {
            dispatch({ type: "LOCK_SET_TEAM", isLocked: isLocked })
        },
        dpDisableSendMail: (isDisableSendMail) => {
            dispatch({ type: "DISABLE_SEND_MAIL", isDisableSendMail: isDisableSendMail })
        },
        dpSetEmailList: (emailList) => {
            dispatch({ type: "SET_EMAIL_LIST", emailList: emailList })
        },
        dpSetMatchDate: (matchDate) => {
            dispatch({ type: "SET_MATCH_DATE", matchDate: matchDate })
        },
        dpInitialSetTeam: (isInitialSetTeam) => {
            dispatch({ type: "SET_INITIAL_SET_TEAM", isInitialSetTeam: isInitialSetTeam })
        },
        dpSetSendPlayer: (sendPlayer) => {
            dispatch({ type: "SET_SEND_PLAYER", sendPlayer: sendPlayer })
        }
    }
}

const reduxConnectExport = connect(mapStateToProps, mapDispatchToProps)(MatchRegister)

export default compose(withFirebase, withAuthorization(condition))(reduxConnectExport)
