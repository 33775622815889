import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Parser from 'html-react-parser';
const moment = require('moment');

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateId: moment(this.selectedDate).format("yyyyMM"),
      penalty: "NA",
    };
  }

  componentWillMount() {
    this.getPlayerInfo();
    this.props.firebase
      .getRef("configuration/finance/penalty")
      .once("value")
      .then((sn) => {
        var fee = [];
        sn.forEach((_sn) => {
          fee.push({ penalty: _sn.key, fee: _sn.val() });
        });
        this.setState({ fee: fee });
      });
    var matchRef = this.props.firebase
      .getRef("matches")
      .orderByKey()
      .startAt(this.state.dateId + "01")
      .endAt(this.state.dateId + "31");
    matchRef.once("value").then((snap) => {
      var allPlayers = [];
      snap.forEach((sn) => {
        allPlayers.push({
          dateId: sn.key,
          players: Object.entries(sn.val().players),
        });
      });
      var playersMap = new Map();
      allPlayers.forEach((_allPlayers) => {
        var thePlayer = _allPlayers.players;
        thePlayer.forEach((_thePlayer) => {
          var total = 0;
          if (
            _thePlayer &&
            _thePlayer[1].penalty &&
            _thePlayer[1].penalty !== "" &&
            _thePlayer[1].penalty !== "none"
          ) {
            var fee = this.state.fee.find(
              (f) => f.penalty === _thePlayer[1].penalty
            )
              ? (fee = parseInt(
                  this.state.fee.find(
                    (f) => f.penalty === _thePlayer[1].penalty
                  ).fee
                ))
              : (fee = 0);
            total += fee;
            if (playersMap.has(_thePlayer[1].name)) {
              playersMap.set(
                _thePlayer[1].name,
                playersMap.get(_thePlayer[1].name) + total
              );
            } else {
              playersMap.set(_thePlayer[1].name, total);
            }
          }
        });
      });
      var penaltyText = "";
      playersMap = new Map(
        [...playersMap.entries()].sort((a, b) => b[1] - a[1])
      );
      var count = 1;
      for (var entry of playersMap.entries()) {
        penaltyText +=
          `<img
          src="/img/medal${count}.png"
          alt="Avatar"
          className="pen-badge"
          /> <div id="penTop${count}">` + entry[0] + ": " + entry[1] + "K </div>";
        count++;
        if (count > 3) {
          break;
        }
      }

      this.setState({ penalty: penaltyText });
    });
  }

  getPlayerInfo = async () => {
    if (this.props.stCurrentUser) {
      if (this.state.id == null) {
        this.props.firebase
          .getRef("players/" + this.props.stCurrentUser.uid)
          .once("value", async (snap) => {
            await this.setState({
              id: snap.key,
              name: snap.val().name,
              defaultAvatar: await this.getCurrentUserAvatar(),
            });
          });
      }
    }
  };

  getCurrentUserAvatar = async () => {
    try {
      const url = await this.props.firebase
        .getStorage()
        .ref("avatar")
        .child(this.props.stCurrentUser.uid)
        .getDownloadURL();
      return url;
    } catch {
      return "/img/dito_tran.png";
    }
  };

  render() {
    this.getPlayerInfo();
    return (
      <nav className="navbar">
        <div className="row">
          <div className="col-sm-10">
            <div id="scroll-text">
              Top phạt tháng này: {Parser(this.state.penalty)}
            </div>
          </div>
          <div className="col-sm-2">
            Hello <b>{this.state.name}!</b>
            <img
              src={this.state.defaultAvatar}
              alt="Avatar"
              className="nav-avatar"
            />
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
        stCurrentUser: state.currentUser
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dpSetTeam: (registeredPlayers) => {
            dispatch({ type: "SET_TEAM", registeredPlayers: registeredPlayers })
        }
    }
}

const reduxConnectExport = connect(mapStateToProps, mapDispatchToProps)(TopBar)
export default compose(withFirebase)(reduxConnectExport)