import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import DashBoard from '../components/content/DashBoard';
import UserDetails from '../components/content/UserDetails';
import Penanties from '../components/content/Penanties'
import Finance from '../components/content/Finance'
import MatchSettings from '../components/content/MatchSettings'
import PlayerManagement from '../components/content/PlayerManagement'

import * as ROUTES from '../constants/routes';
import { connect } from 'react-redux';

class SidebarRouter extends Component {
    getAdminRoute = () => {
        if (this.props.stCurrentUser !== null) {
            if (parseInt(this.props.stCurrentUser.role) === 1) return (
                <>
                    <Route exact path={ROUTES.MATCH_SETTINGS} component={MatchSettings} />
                </>
            )
        }
    }
    render() {
        return (
            <Switch>
                <Route exact path={ROUTES.ROOT} component={DashBoard} />
                <Route exact path={ROUTES.MAIN + '/'} component={DashBoard} />
                <Route exact path={ROUTES.MATCH} component={DashBoard} />
                <Route exact path={ROUTES.USER_DETAILS} component={UserDetails} />
                <Route exact path={ROUTES.PENANTIES} component={Penanties} />
                <Route exact path={ROUTES.FINANCE} component={Finance} />
                <Route exact path={ROUTES.PLAYER_MNG} component={PlayerManagement} />
                {this.getAdminRoute()}
            </Switch>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        stCurrentUser: state.currentUser
    }
}
export default connect(mapStateToProps)(SidebarRouter);